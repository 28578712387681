import React, { useState } from "react";
import { artstudio } from "../../content/artstudio";

const ArtStudioGallery = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    setActiveSlide(
      (prevActiveSlide) => (prevActiveSlide + 1) % artstudio.length
    );
  };
  const handlePrev = () => {
    setActiveSlide(
      (prevActiveSlide) =>
        (prevActiveSlide - 1 + artstudio.length) % artstudio.length
    );
  };

  return (
    <div className="ArtStudioGallery">
      <button onClick={handlePrev} className="prevButton">
        <span className="material-symbols-outlined">arrow_back_ios</span>
      </button>
      <button onClick={handleNext} className="nextButton">
        <span className="material-symbols-outlined">arrow_forward_ios</span>
      </button>
      {artstudio.map((image, index) => {
        return (
          <div
            key={index}
            style={{ display: activeSlide === index ? "block" : "none" }}
          >
            <img 
            src={image.src} 
            alt={image.alt}   
            loading="lazy"
            title={image.title}
            width="auto" height="auto"
             />
          </div>
        );
      })}
    </div>
  );
};

export default ArtStudioGallery;

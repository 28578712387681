import Kiwi from "../media/artpage/StillLife/Kiwi by Luna Smith.jpg";
import GodsDrink from "../media/artpage/StillLife/BourbonByLunaSmith.jpg";
import Lilac from "../media/artpage/StillLife/Lilac by Luna Smith.jpg";
import Dandelion from "../media/artpage/StillLife/DandelionByLunaSmith.jpg";
import Droplets from "../media/artpage/StillLife/Droplets.jpg";
import SnowyPineCone from "../media/artpage/StillLife/SnowyPineCone by Luna Smith.jpg";
import RedRose from "../media/artpage/StillLife/Red Rose by Luna Smith.jpg";
import FlowerOfScotland from "../media/artpage/StillLife/FlowerOfScotland.jpg";
import SunsetInBloom from "../media/artpage/StillLife/Sunset in Bloom by Luna Smith.jpg"; 
import Rocks from "../media/artpage/StillLife/RocksByLunaSmith.jpg";

import KiwiBg from "../media/artpage/StillLife/Kiwi by Luna Smith - background.jpg";
import GodsDrinkBg from "../media/artpage/StillLife/Bourbon by Luna Smith - background.jpg";
import LilacBg from "../media/artpage/StillLife/Lilac by Luna Smith - background1.jpg";
import SnowyPineConeBg from "../media/artpage/StillLife/Snoy PineCone- background.jpg";
import DropletsBg from "../media/artpage/StillLife/Droplets-background.jpg";
import DandelionBg from "../media/artpage/StillLife/DandelionByLunaSmith-background.jpg";
import RedRoseBg from "../media/artpage/StillLife/RedRose_shelf.jpg";
import FlowerOfScotlandBg from "../media/artpage/StillLife/FlowerOfScotland-background.jpg";
import SunsetInBloomBg from "../media/artpage/StillLife/Sunset in Bloom by Luna Smith - background.jpg";
import RocksBg from "../media/artpage/StillLife/RocksByLunaSmith-background.jpg";

export const stillLifeCollection = [
    {    
        src: Kiwi,
        srcBg: KiwiBg,
        alt: "Kiwi by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "fruit, fruits, still life, realism, food",
        title: "Kiwi",
        description:
            "In Kiwi, Scottish artist Luna Smith brings to life the understated elegance of a simple fruit. This painting features a ripe kiwi, one half sliced open to reveal the vibrant green flesh dotted with tiny black seeds, while the other half remains whole. The detailed rendering of the kiwi's texture, from the fuzzy skin to the juicy interior, highlights Smith's meticulous attention to detail and mastery of realism. Set against a subtly blended, dark background with hints of purples and browns, the kiwi stands out as the focal point of the composition. The interplay of light and shadow adds depth and dimension, emphasizing the fruit's natural beauty and making it almost tangible. The background’s muted tones create a sense of calm and focus, allowing the viewer to fully appreciate the kiwi’s vivid green and intricate details.",
        button: "Card",
        price: "£155",
        link: "https://buy.stripe.com/7sIcO5b4LfzR5oYg0P",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=6SZ4TEQM4GDDU",
        dimension: "25 X 20 X 1.5 cm",
    },
    {    
        src: GodsDrink,
        srcBg: GodsDrinkBg,
        alt: "God's Drink by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "bourbon, whiskey, still life, realism, food, Scotland, Scottish",
        title: "God's Drink",
        description:
            "In Gods' Drink, Scottish artist Luna Smith presents a mesmerizing portrayal of a glass of bourbon, exuding a sense of divine luxury and indulgence. This painting features a crystal glass filled with rich, amber-colored bourbon, illuminated by subtle reflections and glimmers of light. The dark, moody background enhances the glass's luminous presence, suggesting an atmosphere of quiet sophistication and contemplation. The bourbon within the glass seems almost ethereal, its warm hues swirling with hints of red and gold, reminiscent of a sunset captured in liquid form. Smith's masterful brushwork captures the transparency and fluidity of the drink, elevating it to the status of a nectar worthy of the gods. The glass itself, with its delicate curves and reflective surfaces, is rendered with exquisite detail, showcasing Smith's keen observational skills and technical prowess.",
        button: "Card",
        price: "£395",
        link: "https://buy.stripe.com/28o29r7SzgDVg3C4i9",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=H8LBMW5ZEGGV6",
        dimension: "24.5 X 35 X 3 cm",
    },
    {    
        src: Lilac,
        srcBg: LilacBg,
        alt: "Lilac by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "flower, flowers, spring, still life, realism, vase",
        title: "Lilac",
        description:
            "In this captivating painting, Luna Smith, a talented Scottish artist hailing from Edinburgh, presents a beautiful bouquet of lilacs. The vibrant lilac flowers, interspersed with hints of green foliage, create a lively and textured composition. Set against a softly blended, pastel background, the rich colors of the lilacs pop, bringing a sense of freshness and vitality. The terracotta pot adds a grounding element, contrasting with the ethereal quality of the blossoms. Smith's brushwork and attention to detail capture the delicate nature of the flowers, making Lilac a stunning representation of natural beauty and artistic skill.",
        button: "Card",
        price: "£55",
        link: "https://buy.stripe.com/00g15n5Kr9bteZydSO",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7S6M75GFHDLPJ",
        dimension: "19.5 X 14.5 X 1.5 cm",
    },
    {    
        src: RedRose,
        srcBg: RedRoseBg,
        alt: "Red Rose by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "flower, flowers, still life, realism, winter, snowy",
        title: "Red Rose",
        description:
            "This artwork showcases a rich, deep red rose in full bloom, lying horizontally against a textured white background. The vivid hues of the rose's petals are skillfully contrasted with the subtle greens of its stem and leaves, drawing the viewer's eye to the intricate details and lifelike quality of the flower. The textured white background adds depth and dimension, enhancing the rose's vibrant color and giving the painting a tactile quality. This juxtaposition of the bold, passionate red with the serene, neutral backdrop evokes a sense of elegance and timeless beauty. Smith's masterful use of color and texture demonstrates her keen observation and artistic prowess, making Red Rose a captivating piece that celebrates the enduring allure of this iconic flower. ",
        button: "Card",
        price: "£75",
        link: "https://buy.stripe.com/dR68xP6Ov2N54kUcOH",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7S6M75GFHDLPJ",
        dimension: "25 X 14.5 X 3 cm",
    },
    {    
        src: SnowyPineCone,
        srcBg: SnowyPineConeBg,
        alt: "Snowy Pinecone by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "pine cone, still life, realism, Valentine, mother' day",
        title: "Snowy Pinecone",
        description:
            "The painting centers on a single pinecone, dusted with a delicate layer of snow, set against a textured, off-white background. The pinecone, with its rich browns and subtle hints of red and gold, stands out vividly, drawing the viewer's eye to its intricate layers and natural form. Smith's use of thick, impasto brushstrokes adds a tactile quality to the snow, giving it a sense of weight and texture that contrasts beautifully with the sturdy yet detailed structure of the pinecone. The interplay of light and shadow across the scales of the pinecone highlights its three-dimensionality, while the soft, muted background enhances the overall sense of tranquility and focus.",
        button: "Sold",
        price: "£55",
        link: "",
        linkPayPal: "",
        dimension: "14.5 X 19.5 X 1.5 cm",
    },
    {    
        src: Dandelion,
        srcBg: DandelionBg,
        alt: "Dandelion by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "flower, flowers, still life, realism",
        title: "Dandelion",
        description:
            "In Dandelion, a captivating work by the distinguished Scottish painter Luna Smith, the ephemeral beauty of nature takes center stage. This exquisite oil painting presents a dandelion in the last stage of its life cycle, poised to release its delicate seeds into the gentle embrace of the wind. Against a backdrop of lush, sun-kissed green grass, Luna masterfully captures the essence of a fleeting yet extraordinary moment. ",
        button: "Sold",
        price: "£55",
        link: "",
        linkPayPal: "",
        dimension: "19.5 X 14.5 X 1.5 cm",
    },
    {    
        src: Droplets,
        srcBg: DropletsBg,
        alt: "Droplets by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "water, leaf, still life, realism, closeup",
        title: "Droplets",
        description:"Droplets, a captivating masterpiece by the talented Scottish painter Luna Smith, brings the natural world to life in the most delicate and detailed manner. This enchanting oil painting captures the essence of a moment of pure serenity. The focal point of the artwork is a vibrant green leaf, its surface adorned with glistening droplets of water, each one like a tiny jewel in nature's crown.",
        button: "Sold",
        price: "£55",
        link: "",
        linkPayPal: "",
        dimension: "14.5 X 19.5 X 1.5 cm",
    },
    {    
        src: Rocks,
        srcBg: RocksBg,
        alt: "Droplets by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "stone, stones, still life, realism, rocky beach",
        title: "Droplets",
        description:"",
        button: "Sold",
        price: "£55",
        link: "",
        linkPayPal: "",
        dimension: "19.5 X 14.5 X 1.5 cm",
    },
    {    
        src: FlowerOfScotland,
        srcBg: FlowerOfScotlandBg,
        alt: "Flower of Scotland by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "flower, flowers, purple, still life, realism, Scottish",
        title: "Flower of Scotland",
        description:"This painting showcases a cluster of blooming thistles, their delicate pink and purple petals standing out vividly against the deep green backdrop of their foliage. The dark, moody tones of the background contrast beautifully with the light, ethereal quality of the flowers, creating a sense of depth and mystery. Smith's technique captures the rugged yet beautiful essence of the thistle, a symbol of resilience and endurance. The meticulous attention to detail in the petals and leaves highlights the intricate natural patterns and textures, while the soft, diffused lighting evokes the quiet majesty of Scotland's wild landscapes. The overall composition draws the viewer into a serene, almost meditative appreciation of this hardy yet elegant flower.",
        button: "Card",
        price: "£55",
        link: "https://buy.stripe.com/7sIaFX4Gn1J18Ba3e8",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=X248KCRBNZ6BU",
        dimension: "24 X 18 X 1.5 cm",
    },
    {    
        src: SunsetInBloom,
        srcBg: SunsetInBloomBg,
        alt: "Sunset in Bloom by Luna Smith - oil painting",
        about: "Still Life",
        keyWords: "flower, flowers, still life, realism",
        title: "Sunset in Bloom",
        description:
        "This piece captures the essence of a vibrant floral garden in full bloom. The textured brushstrokes create a rich tapestry of colors, with shades of pink, red, yellow, and blue intertwining to form a harmonious and lively composition. The background transitions from a warm pink to a subtle hint of sunlight, suggesting the gentle glow of a setting or rising sun. The use of the thick impasto technique adds depth and dimension, making the flowers appear almost three-dimensional. Luna Smith's skillful manipulation of color and texture invites the viewer to immerse themselves in the beauty and energy of this radiant garden scene.",
        button: "Sold",
        price: "£55",
        link: "",
        linkPayPal: "",
        dimension: "19.5 X 14.5 X 1.5 cm",
    },
]
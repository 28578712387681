import React, { useState } from "react";
import Shadows from "./Shadows";
import Landscapes from "./Landscapes";
import DonateFooter from "../Footer/DonateFooter";
import Animals from "./Animals";
import Nott from "./Nott";
import SoundofLife from "./SoundofLife";
import { motion } from "framer-motion";
import AllPaintings from "./AllPaintings";
import { FaSearch } from 'react-icons/fa';
import Portraits from "./Portraits";
import Abstracts from "./Abstracts";
import StillLife from "./StillLife";

interface PaintingProps {
  src: string;
  srcBg: string;
  alt: string;
  about: string;
  title: string;
  description: string;
  button: string;
  price: string;
  link: string;
  linkPayPal: string;
  dimension: string;
  keyWords: string;
}

interface ModeProps {
  mode: string;
  category: string;
}

const ArtSection = ({ mode, category }: ModeProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleScroll = () => {
    const targetElement = document.getElementById("artTop");
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const filterPaintings = (painting: PaintingProps) => {
    return (
      painting.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      painting.keyWords.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <div className={`ArtSection ${mode === 'light' ? 'lightMode' : 'darkMode'}`} id="artTop">
      <motion.h2
        className="freeDelivery"
        initial={{ y: -60 }}
        whileInView={{ y: 2, transition: { duration: 0.5 } }}
      >
        <motion.strong>Free delivery for any original painting.</motion.strong>
      </motion.h2>
      <h2 className="clickAway">
        <strong>Acquiring stunning art is a click away.</strong>
      </h2>
      <div className="topBox">
        <h1>Scottish Fine Art <span>- Luna Smith</span></h1>
        <div className="searchBox">
          <FaSearch className='searchIcon' />
          <input
            className="searchInput"
            type="text"
            placeholder="Search titles..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        {searchQuery.length > 0 && <AllPaintings filterFunction={filterPaintings} />}
        <h2 className="intro">
          Scottish contemporary fine art is a vibrant and diverse genre that
          encompasses a wide range of styles and subject matter. From stunning
          landscapes to captivating abstracts, from expressive animal portraits
          to powerful figurative pieces, the Luna Smith's oil painting offers
          something for every taste and sensibility.
        </h2>
      </div>
      {category === 'all' && (
        <>
          <Landscapes />
          <button onClick={handleScroll}>Scroll to Top</button>
          <Animals />
          <button onClick={handleScroll}>Scroll to Top</button>
          <Nott />
          <button onClick={handleScroll}>Scroll to Top</button>
          <SoundofLife />
          <button onClick={handleScroll}>Scroll to Top</button>
          <Shadows />
          <button onClick={handleScroll}>Scroll to Top</button>
          <Portraits />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'landscapes' && (
        <>
          <Landscapes />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'soundoflife' && (
        <>
          <SoundofLife />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'animals' && (
        <>
          <Animals />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'abstracts' && (
        <>
          <Abstracts />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'nott' && (
        <>
          <Nott />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'shadows' && (
        <>
          <Shadows />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'stilllife' && (
        <>
          <StillLife />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      {category === 'portraits' && (
        <>
          <Portraits />
          <button onClick={handleScroll}>Scroll to Top</button>
        </>
      )}
      <h4>
        Within 3 days of a purchase, we will carefully pack and ship your
        painting, providing you with a tracking number to keep you informed
        about the arrival of the artwork.
      </h4>
      <DonateFooter mode={mode} />
    </div>
  );
};

export default ArtSection;
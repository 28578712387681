import React from 'react';
import { useParams, Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaPinterest,
  FaWhatsappSquare
} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai"
import { 
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

interface ArtworkItem {
  src: string;
  srcBg: string;
  alt: string;
  about: string;
  title: string;
  description: string;
  button: string;
  price: string;
  link: string;
  linkPayPal: string;
  dimension: string;
  keyWords: string;
}

interface PaintingProps {
  artworks: ArtworkItem[];
  mode: string,
}


const Artwork: React.FC<PaintingProps> = ({ artworks, mode }) => {
  const { title: artworkTitle } = useParams();


  //Trim the artworkTitle once and store it in a variable
  const trimmedArtworkTitle = artworkTitle?.replace(/\s+/g, '') || '';

  // Filter the artworks that include the trimmedArtworkTitle as part of their title
  const filteredArtworks = artworks.find( art =>
    art.title.replace(/\s+/g, '').toLowerCase() ===
    trimmedArtworkTitle?.toLowerCase());

  if (!filteredArtworks) {
    // Handle the case when no matching artworks are found
    return <div>No artworks found for the given title.</div>;
  }

  return (
    <div>
        <div className={`Artwork ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
            <Link to="/art"><div className='close'>X</div></Link>
            <div className='imageContainer'>
              <Link to={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}.jpg`}>
                <img src={filteredArtworks.src} alt={filteredArtworks.alt} />
              </Link>
              <img src={filteredArtworks.srcBg} alt={filteredArtworks.alt} className='small' />
            </div>
            <h2><span>{filteredArtworks.title}</span></h2>
            <h3>Oil painting on canvas</h3>
            <h3>Artist: Luna Smith</h3>
            <h3>Dimensions: {filteredArtworks.dimension}</h3>
            <h4><span>{filteredArtworks.price}</span></h4>
            <p>{filteredArtworks.description}</p>
            {filteredArtworks.button === "Sold" ? <button className='soldButton'>{filteredArtworks.button}</button> : 
            filteredArtworks.button === "Reserved" ?
            <Link to={filteredArtworks.link}><button>{filteredArtworks.button}</button></Link> :
              <div className='buttons'>
                <div className='buy'><span>Buy</span></div>
                <Link to={filteredArtworks.link}><button>{filteredArtworks.button}</button></Link>
                <Link to={filteredArtworks.linkPayPal}><button className='payPal'>PayPal</button></Link>
              </div>
            }
            </div>
            <div className='socialMediaShare'>
              <span className='shareTitle'>Share via</span>
              <TwitterShareButton 
              url={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}`} 
              title={`'${filteredArtworks.title}', an oil painting on canvas, artist: Luna Smith.`}> 
                <span><FaTwitter />Twitter</span>
              </TwitterShareButton>
              <FacebookShareButton 
              url={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}`} 
              quote={`'${filteredArtworks.title}' - an oil painting on canvas, artist: Luna Smith. #painting #fineart #LunaSmith #homedecor`}
              hashtag='#LuArtGallery, #LunaSmith, #homedecor'
              > 
                <span><FaFacebook />Facebook</span>
              </FacebookShareButton>
              <PinterestShareButton 
              url={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}`} 
              title={`'${filteredArtworks.title}', an oil painting on canvas, artist: Luna Smith.`} 
              media={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}.jpg`} 
              description={filteredArtworks.title}>
                <span><FaPinterest />Pinterest</span>
              </PinterestShareButton>
              <EmailShareButton 
              url={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}`} 
              title={`'${filteredArtworks.title}', an oil painting on canvas, artist: Luna Smith.`}
              subject={`'${filteredArtworks.title}', LuArt Gallery`}>
                <span><AiOutlineMail />Email</span>
              </EmailShareButton> 
              <WhatsappShareButton 
              url={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}`} 
              title={`'${filteredArtworks.title}', an oil painting on canvas, artist: Luna Smith.`}>
                <span>< FaWhatsappSquare />WhatsApp</span>
              </WhatsappShareButton>
            </div>
    </div>
  );
}

export default Artwork;
class CanvasAnimation {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    mouse: { x: number; y: number };
    shapes: any[];
  
    constructor(canvas: HTMLCanvasElement) {
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d')!;
      this.mouse = { x: 0, y: 0 };
      this.shapes = [];
      this.resizeCanvas();
      this.addEventListeners();
      this.animate();
    }
  
    resizeCanvas() {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
    }
  
    addEventListeners() {
      window.addEventListener('resize', () => this.resizeCanvas());
      window.addEventListener('mousemove', (event) => {
        this.mouse.x = event.clientX;
        this.mouse.y = event.clientY;
      });
    }
  
    createShape() {
      const size = Math.random() * 30 + 5;
      let color = 'rgb(142, 105, 26)';
      
      return {
        x: this.mouse.x,
        y: this.mouse.y,
        size,
        color,
        dx: (Math.random() - 0.5) * 4,
        dy: (Math.random() - 0.5) * 4,
      };
    }
  
    drawShape(shape: any) {
        this.ctx.fillStyle = shape.color;
        this.ctx.fillRect(shape.x, shape.y, shape.size, shape.size);
    }
  
    animate = () => {
      requestAnimationFrame(this.animate);
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      
      if (Math.random() < 0.1) {
        this.shapes.push(this.createShape());
      }
  
      this.shapes.forEach((shape, index) => {
        shape.x += shape.dx;
        shape.y += shape.dy;
        shape.size *= 0.98;
        if (shape.size < 0.5) {
          this.shapes.splice(index, 1);
        } else {
          this.drawShape(shape);
        }
      });
    };
  }
  
  export default CanvasAnimation;
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useBasket } from '../BasketContext';
import { FaShoppingBasket } from "react-icons/fa";

interface PaintingProps {
    src: string;
    srcBg: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    price: string;
    link: string;
    linkPayPal: string;
    dimension: string;
    keyWords: string;
}

const ArtGallery: React.FC<PaintingProps> = ({ src, alt, title, button, dimension, price, link, linkPayPal, keyWords }) => {
  const { basket, addToBasket } = useBasket();

  // Check if the item is already in the basket
  const isInBasket = basket.some(item => item.title === title);

  return (
    <motion.div className='ArtGallery'
      initial={{ scale: 0.5, opacity: 0.5 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{ duration: 1}}>
      <div className='paintingDescription'>
        <Link className='paintingImage' to={`/art/${title.replace(/\s+/g, '')}`}>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 0.8 } }}>
            <img src={src} alt={alt} title={title} loading="lazy" width="auto" height="auto" />
          </motion.div>
        </Link>
        <div className='paintingDescriptionContainer'>
          <Link to={`/art/${title.replace(/\s+/g, '')}`}>
            <h2><span>{title}</span></h2>
            <div>
              <h3>Oil painting on canvas</h3>
              <h3>Artist: Luna Smith</h3>
              <h3>Dimensions: {dimension}</h3>
            </div> 
            <h4>{price}</h4>
          </Link>
          {button === "Sold" ? 
            <button className='soldButton'>{button}</button> : 
            <div className='buttons'>
              <div className='buy'><span>Buy</span></div>
              <Link to={link}><button className='card' disabled={isInBasket}>{button}</button></Link>
              <Link to={linkPayPal}><button className='payPal' disabled={isInBasket || button === "Reserved" }>PayPal</button></Link>
              <button
                className="basketButton"
                onClick={() => addToBasket({ src, alt, title, dimension, price })}
                disabled={isInBasket || button === "Reserved"} 
              >
                Add
                <div className='basket'><FaShoppingBasket /></div>
              </button>
            </div>
          }
        </div>
      </div>
    </motion.div>
  );
};

export default ArtGallery;